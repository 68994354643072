<template>
  <div class="col main_institutions_wrapper">
    <div
      class="
        d-flex
        align-items-center
        justify-content-between
        mb-3
        flex-wrap
      "
    >
      <!-- Institution Name  -->
      <h1 class="h3 my-2 my-lg-0">
        {{ institutionData ? institutionData[0].value : "" }}
      </h1>
      <el-button
        type="success"
        v-tooltip.top-center="`${localization('Edit Institution')}`"
        @click="open_institution_dialog()"
        size="medium"
      >
        <i class="fa fa-edit"></i>
      </el-button>
    </div>

    <Info :items="institutionData" />
    <!--//? Large Screens  -->
    <server-table
      class="hidden-sm-only hidden-xs-only"
      :count="$store.getters['user/totalUsers']"
      :DataItems="$store.getters['user/users']"
      @Refresh="Refresh"
      @selection="selections"
      :loading="$store.getters['user/usersLoad']"
      hidden_export_import
      :title="`${localization('Institutions')}`"
      admin_permission="admin_users"
      edit_permission="add_tasks"
    >
      <template #columns>
        <el-table-column type="selection" width="60"></el-table-column>
        <el-table-column
          prop="first_name"
          :label="`${localization('Name')}`"
          sortable
          min-width="160"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                    word-break: break-word;
                width: 100%;
              "
            >
              {{ `${scope.row.first_name} ${scope.row.last_name}` }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          :label="`${localization('Email')}`"
          min-width="180"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                    word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.email }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="country"
          sortable
          :label="`${localization('Location')}`"
          min-width="150"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.city }} {{ scope.row.city ? "," : "" }}
              {{ scope.row.state }} {{ scope.row.state ? "," : "" }}
              {{ scope.row.country }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="age_group_id"
          sortable
          :label="`${localization('Age')}`"
        >
          <template slot-scope="scope">
            {{ getAgeGroup(scope.row.age_group_id) }}
          </template>
        </el-table-column>

        <el-table-column
          :label="`${localization('Score')}`"
          min-width="150"
          v-if="!hasPermission('all_users') && hasPermission('access_users')"
        >
          <template slot-scope="scope">
            <span
              class="d-inline-block font-weight-bold h5 m-1 p-2 text-white"
              style="background-color: #39c"
              >{{ scope.row.sequence }}</span
            >
            <span
              class="d-inline-block font-weight-bold h5 m-1 p-2 text-white"
              style="background-color: #3c3"
              >{{ scope.row.precision }}</span
            >
            <span
              class="d-inline-block font-weight-bold h5 m-1 p-2 text-white"
              style="background-color: #f93"
              >{{ scope.row.technical_reasoning }}</span
            >
            <span
              class="d-inline-block font-weight-bold h5 m-1 p-2 text-white"
              style="background-color: #c36"
              >{{ scope.row.confluence }}</span
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="removed"
          :label="`${localization('Status')}`"
          sortable
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 0px; padding: 1px 6px"
            >
              {{
                scope.row.removed == 0
                  ? `${localization("Active")}`
                  : `${localization("Inactive")}`
              }} </l-button
            >&nbsp;
          </template>
        </el-table-column>

        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <!-- View Profile  -->
              <a
                v-if="
                  hasPermission('manage_institutions') ||
                    hasPermission('show_users')
                "
                v-tooltip.top-center="`${localization('View profile')}`"
                class="btn btn-info btn-link btn-xs mr-2"
                @click="$router.push(`/user-profile/${scope.row.id}`)"
              >
                <i class="fa fa-user" style="font-size: 17px"></i>
              </a>
              <!-- //// Admin Institution --- just for him -->
              <a
                v-if="hasPermission('manage_institutions')"
                v-tooltip.top-center="`Remove user from institution`"
                @click="removeUserFromInstitutions(scope.row.id)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
              </a>
              <!-- Delete or Reactivate User  -->
              <!-- <a
                v-tooltip.top-center="
                  scope.row.removed == 0 ? 'Delete' : 'Active'
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a> -->
            </div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <button
          @click="open_task_dialog"
          class="border-0 text-dark"
          style="background: transparent"
          :disabled="selected.length == 0"
        >
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Push Task") }}
        </button>
      </template>
    </server-table>

    <!-- //? Small & medium Screens  -->
    <server-table
      class="hidden-md-only hidden-lg-only hidden-xl-only"
      :count="$store.getters['user/totalUsers']"
      :DataItems="$store.getters['user/users']"
      @Refresh="Refresh"
      @selection="selections"
      :loading="$store.getters['user/usersLoad']"
      hidden_export_import
      :title="`${localization('Institutions')}`"
      admin_permission="admin_users"
      edit_permission="add_tasks"
    >
      <template #columns>
        <el-table-column type="selection" width="60"></el-table-column>
        <el-table-column
          prop="first_name"
          :label="`${localization('Name')}`"
          min-width="160"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                    word-break: break-word;
                width: 100%;
                white-space: normal;
              "
              class="
                d-flex
                flex-column flex-sm-row
                justify-content-sm-between
                align-items-sm-center
              "
            >
              <div>
                <span
                  class="d-block"
                  style="
                  white-space: normal;
                    word-break: break-word;
              "
                  >{{ `${scope.row.first_name} ${scope.row.last_name}` }}</span
                >
                <span
                  class="d-block"
                  style="
                  white-space: normal;
                    word-break: break-word;

              "
                  >{{ scope.row.email }}</span
                >
              </div>
              <div class="">
                <a
                  v-if="
                    hasPermission('manage_institutions') ||
                      hasPermission('show_users')
                  "
                  v-tooltip.top-center="`${localization('View profile')}`"
                  class="btn btn-info btn-link btn-xs mr-2"
                  @click="$router.push(`/user-profile/${scope.row.id}`)"
                >
                  <i class="fa fa-user" style="font-size: 17px"></i>
                </a>
                <!-- //// Admin Institution --- just for him -->
                <a
                  v-if="hasPermission('manage_institutions')"
                  v-tooltip.top-center="`Remove user from institution`"
                  @click="removeUserFromInstitutions(scope.row.id)"
                  :class="
                    scope.row.removed == 0
                      ? `btn btn-danger btn-link btn-xs`
                      : `btn btn-success btn-link btn-xs`
                  "
                >
                  <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                </a>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <button
          @click="open_task_dialog"
          class="border-0 text-dark"
          :disabled="selected.length == 0"
        >
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Push Task") }}
        </button>
      </template>
    </server-table>

    <!-- //! start Push task dialog  -->
    <el-dialog
      :title="`${localization('Push Task')}`"
      :visible.sync="task_dialog"
      top="4vh"
      width="60%"
      class="push_dialog_task_user"
    >
      <el-form :model="task" ref="pushTaskFormRef" :rules="pushTaskRules">
        <el-row :gutter="10"> </el-row>
        <el-form-item :label="`${localization('Name')}`" prop="name">
          <el-input
            v-model="task.name"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="`${localization('Description')}`"
          prop="description"
        >
          <el-input
            v-model="task.description"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item :label="`${localization('Topic')}`" prop="topic_id">
          <el-select
            v-model="task.topic_id"
            clearable
            :placeholder="`${localization('Topic')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in $store.getters['topics/topics']"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer dialog_footer">
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="task_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          class="text-center text-capitalize save_button "
          @click="PushTask"
          >{{ localization("Push Task") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- //! end Push task dialog  -->

    <!-- //? start  Add or Edit Institution  -->
    <el-dialog
      :title="`${localization('Institutions')}`"
      :visible.sync="institution_dialog"
      top="4vh"
      width="90%"
    >
      <el-row :gutter="10">
        <el-col class="my-4 part_one_card" :xs="24" :md="12">
          <el-form
            :model="institution"
            ref="institutionRef"
            :rules="institutionRules"
            label-position="top"
          >
            <el-card class="mr-2 mb-3">
              {{ localization("Edit Institutions Info") }}
            </el-card>
            <el-col :span="24">
              <el-form-item :label="`${localization('Name')}`" prop="name">
                <el-input v-model="institution.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item
                :label="`${localization('Promo Code')}`"
                prop="promo_code"
              >
                <el-input v-model="institution.promo_code"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item
                :label="`#${localization('Of Seats')}`"
                prop="max_users"
              >
                <el-input v-model="institution.max_users"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`#${localization('Expiration Date')}`"
                prop="expiration_date"
              >
                <el-date-picker
                  v-model="institution.expiration_date"
                  format="dd / MM / yyyy"
                  value-format="yyyy-MM-dd"
                  :picker-options="datePickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="`#${localization('Notes')}`">
                <el-input v-model="institution.notes"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`#${localization('Contact Name')}`"
                prop="contact_name"
              >
                <el-input v-model="institution.contact_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item :label="`${localization('Email')}`" prop="email">
                <el-input v-model="institution.email"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item
                :label="`${localization('Phone')}`"
                prop="phone_number"
              >
                <el-input v-model="institution.phone_number"></el-input>
              </el-form-item>
            </el-col>
            <!-- Categories Select Box  -->
            <el-col :span="24">
              <el-form-item :label="`${localization('Category')}`" prop="">
                <el-select
                  v-model="institution.task_category_id"
                  :placeholder="`${localization('Please select category')}`"
                  style="width: 100%"
                  multiple
                >
                  <el-option
                    v-for="item in categories"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- APE  -->
            <el-col :md="12" :xs="24">
              <el-form-item
                :label="`${localization('ABE')}`"
                prop="abe"
                style="line-height:normal"
              >
                <el-checkbox v-model="institution.abe"></el-checkbox>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <div class="dialog_footer">
                <el-button
                  class="text-center text-capitalize save_button delete_button"
                  @click="institution_dialog = false"
                >
                  {{ localization("Cancel") }}
                </el-button>
                <el-button
                  class="text-center text-capitalize save_button"
                  @click="saveInstitution"
                >
                  {{ localization("Save") }}</el-button
                >
              </div>
            </el-col>
          </el-form>
        </el-col>
        <!-- //? Start Payment  -->
        <el-col class="my-4 part_two_card" :xs="24" :md="12">
          <el-form
            :model="institution_payment"
            ref="paymentRef"
            label-position="top"
            class=""
          >
            <el-card
              class="mr-2 mb-3"
              style="
                width: 100%;
                background: #909399;
                color: #fff;
              "
            >
              {{ localization("Make A Payment") }}
            </el-card>
            <el-col :span="24">
              <el-form-item
                :label="`${localization('Card Holder Name')}`"
                label-width="180px"
              >
                <el-input v-model="institution_payment.card_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`${localization('Credit Card Number')}`"
                label-width="180px"
              >
                <el-input
                  v-model="institution_payment.card_number"
                  maxlength="16"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`${localization('Credit Card Expiration Date')}`"
                label-width="180px"
              >
                <el-date-picker
                  v-model="institution_payment.exp_time"
                  format="MM/yy"
                  value-format="yyyy-MM"
                  type="month"
                  :picker-options="datePickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`${localization('CVC')}`"
                label-width="180px"
              >
                <el-input
                  v-model="institution_payment.cvc_code"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`$${localization('Amount')}`"
                label-width="180px"
              >
                <el-input v-model="institution_payment.amount"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="p-lg-0">
              <div
                class="
                  dialog-footer
                  dialog_footer
                "
              >
                <el-button
                  class="text-center text-capitalize save_button delete_button"
                  @click="institution_dialog = false"
                >
                  {{ localization("Cancel") }}
                </el-button>
                <el-button
                  class="text-center text-capitalize save_button"
                  @click="processPayment"
                >
                  {{ localization("Submit") }}
                </el-button>
              </div>
            </el-col>
          </el-form>
        </el-col>
        <!-- //? End Payment  -->
      </el-row>
    </el-dialog>
    <!-- //? end  Add or Edit Institution  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Info from "../../components/Cards/Info";
import Swal from "sweetalert2";
import "element-ui/lib/theme-chalk/display.css";

export default {
  components: { ServerTable, Info },
  data: () => ({
    // general data
    filter: {},
    selected: [],
    // push task data
    task: {},
    task_dialog: false,
    pushTaskRules: {
      name: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      description: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      topic_id: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }
      ]
    },
    // institution & edit institution data
    institution: {},
    institution_dialog: false,
    institutionRules: {
      name: [
        {
          min: 6,
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      promo_code: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      max_users: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      expiration_date: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],

      contact_name: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      email: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      phone_number: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ]
    },
    datePickerOptions: {
      disabledDate(date) {
        return date < new Date();
      }
    },
    institution_payment: {}
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    categories() {
      return this.$store.getters["organization/getCategories"];
    },
    // store & format institution data to be displayed at Info component
    institutionData() {
      let data = this.$store.getters["organization/singleOrganization"];
      return (
        data &&
        Object.entries(data)
          .map(([key, value]) => {
            return { key, value };
          })
          .filter(
            item =>
              item.key !== "old_id" &&
              item.key !== "id" &&
              item.key !== "removed" &&
              item.key !== "isEnabled" &&
              item.key !== "classroom_id" &&
              item.key !== "updated_at"
          )
      );
    }
  },
  mounted() {
    // dispatch organization to get organization data
    this.$store.dispatch("organization/organization", this.id);
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("user/users", {
        query: { ...query, organization_id: this.id }
      });
    },
    open_institution_dialog() {
      this.institution = this.$store.getters["organization/singleOrganization"];
      this.institution_dialog = true;
    },
    // push task to selected users function
    PushTask() {
      this.$refs["pushTaskFormRef"].validate(valid => {
        if (valid) {
          this.task.users_id = this.selected;
          this.task.description = this.task.description.replace(/\s/g, "_");
          this.$store
            .dispatch("user/push_task", { query: this.task })
            .then(_ => {
              this.task_dialog = false;
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        } else {
          console.log("erorr");
          return false;
        }
      });
    },
    //// Start Remove User From Institutions
    removeUserFromInstitutions(id) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/deleteUserFromInstitutions", id)
            .then(_ => {
              this.Refresh(null);
              Swal.fire({
                text: `${this.localization("Deleted")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                confirmButtonText: `${this.localization("Ok")}`
              });
            });
        }
      });
    },
    //// End Remove User From Institutions
    // store selected user
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
    },
    // translate group id numbers to valid strings
    getAgeGroup(id) {
      switch (id) {
        case 1:
          return "6-12 years";
        case 2:
          return "13 -21 years";
        case 3:
          return "22 or older";
        case 4:
          return "ABE";
        default:
          return "-";
      }
    },
    // open push task dialog
    open_task_dialog() {
      this.task_dialog = true;
      // Get topics for push task form
      this.$store.dispatch("topics/GetTopics", { query: null });
    },

    // save institution editing
    saveInstitution() {
      // this indicate for ADD INSTITUTION form only not
      //  PAYMENT FORM, so after adding function for payment submition, check for both forms validation
      this.$refs["institutionRef"].validate(valid => {
        if (valid) {
          const requestInstitution = { ...this.institution };
          delete requestInstitution.remaining_seats;
          if (this.type == 1) {
            this.$store
              .dispatch("organization/saveNewOrganization", {
                query: requestInstitution
              })
              .then(_ => {
                this.Refresh({});
                this.institution_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("organization/saveOrganization", {
                id: this.institution.id,
                query: requestInstitution
              })
              .then(_ => {
                this.Refresh({});
                this.institution_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // Institution Payment functions

    processPayment() {
      // this indicate for ADD INSTITUTION form only not
      //  PAYMENT FORM, so after adding function for payment submition, check for both forms validation

      // this.$refs["paymentRef"].validate((valid) => {
      //   if (valid) {
      if (this.type == 1) {
        this.$store
          .dispatch("organization/saveNewOrganization", {
            query: this.institution_payment
          })
          .then(_ => {
            this.Refresh({});
            this.institution_dialog = false;
            Swal.fire({
              title: "",
              icon: "success",
              text: `${this.localization("Done")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              type: "success",
              confirmButtonClass: "btn btn-success ",
              buttonsStyling: false
            });
          });
      } else {
        this.$store
          .dispatch("organization/saveOrganization", {
            id: this.institution.id,
            query: this.institution_payment
          })
          .then(_ => {
            this.Refresh({});
            this.institution_dialog = false;
            Swal.fire({
              title: "",
              icon: "success",
              text: `${this.localization("Done")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              type: "success",
              confirmButtonClass: "btn btn-success ",
              buttonsStyling: false
            });
          });
      }
      // } else {
      //   console.log("error submit!!");
      //   return false;
      // }
      // });
    }
  }
};
</script>

<style scoped lang="scss">
.part_one_card {
  border-right: 1px solid #dcdfe6;
}
.part_two_card {
  padding-left: 8px !important;
}

::v-deep .el-form-item__content {
  line-height: normal !important;
}
// check box and labels
::v-deep .el-form-item__label {
  padding: 0;
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    border-color: #00563f;
    background-color: #00563f;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>

<style lang="scss">
.main_institutions_wrapper {
  .el-dialog__body {
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 30px 5px;
    }
  }
  .push_dialog_task_user {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
</style>
